import { eventChannel } from 'redux-saga';
import { getCollection } from '../../realm';
import { callPageFunction } from '../../utils/callPageFunction';
import * as Realm from 'realm-web';
import {
  IShopifyOrder,
  ISubstitutesProduct,
  ShopifyBopisStatusEnum,
} from '../../types/shopify/TypeOrder';
import {
  IMongoDbOrderStatus,
  IMongodbOrder,
  IMongodbOrderFulfillmentStatus,
  IMongodbUser,
  MongoDbOrderStatusEnum,
} from '../../types/mongodb';
import { getSimplifiedOrder } from '../../utils/order';
import { IFindOrdersQuery, IOrder, IStoreOrdersCount } from '../../types/fulfillments/Order';

export const getMongodbOrderById = async (orderId: string): Promise<IMongodbOrder | null> => {
  try {
    const ordersCollection = getCollection<IMongodbOrder>('orders');
    const order = await ordersCollection.findOne({ _id: orderId });
    if (order) {
      return Realm.BSON.EJSON.parse(JSON.stringify(order)) as IMongodbOrder;
    }
    return order;
  } catch (err) {
    throw err;
  }
};

export const getOrdersForStore = async (
  locationCode: string,
  status: IMongoDbOrderStatus,
): Promise<{ orders: IOrder[] } | never> => {
  try {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const result = (await callPageFunction(
      `/api/private/orders/getOrdersByStatus?locationCode=${locationCode}&status=${status}`,
    )) as {
      shopifyOrders: IShopifyOrder[];
      mongodbOrders: IMongodbOrder[];
      products?: ISubstitutesProduct[];
    };
    const orders = result.mongodbOrders?.map((mongoDbOrder, index) => {
      if (mongoDbOrder._id !== result.shopifyOrders?.[index].id) {
        const shopifyOrder = result.shopifyOrders.find(order => order.id === mongoDbOrder._id);
        if (shopifyOrder) {
          return getSimplifiedOrder(mongoDbOrder, shopifyOrder);
        }
      }
      return getSimplifiedOrder(mongoDbOrder, result.shopifyOrders?.[index]);
    });

    return {
      orders,
    };
  } catch (error) {
    throw error;
  }
};
export const getOrder = async (id: string): Promise<IOrder | never> => {
  try {
    const result = (await callPageFunction(`/api/private/orders/getOrderById?orderId=${id}`)) as {
      mongodbOrder: IMongodbOrder;
      shopifyOrder: IShopifyOrder;
      substituteProducts?: ISubstitutesProduct[];
    };
    if (result.mongodbOrder && result.shopifyOrder) {
      const order = getSimplifiedOrder(result.mongodbOrder, result.shopifyOrder);
      return order;
    } else {
      throw new Error('order not found');
    }
  } catch (error) {
    throw error;
  }
};

export const mongodbOrdersWatchEmitter = async (locationCode: string) => {
  return eventChannel(emitter => {
    const ordersCollection = getCollection('orders');
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to 00:00:00.000 UTC

    const tomorrow = new Date(today);
    tomorrow.setUTCDate(today.getUTCDate() + 1); // Set time to 00:00:00.000 UTC of the next day
    const filter = {
      filter: {
        $and: [
          { 'fullDocument.fulfillmentLocationCode': locationCode },
          {
            $or: [
              { 'fullDocument.status': MongoDbOrderStatusEnum.NEEDS_PACKING },
              { 'fullDocument.status': MongoDbOrderStatusEnum.PACKING_STARTED },
              { 'fullDocument.status': MongoDbOrderStatusEnum.CANCELED },
              { 'fullDocument.status': MongoDbOrderStatusEnum.UNFULFILLABLE },
              { 'fullDocument.status': MongoDbOrderStatusEnum.READY_FOR_PICKUP },
              { 'fullDocument.status': MongoDbOrderStatusEnum.CANCELED_RESHELVING_CONFIRMED },
              { 'fullDocument.status': MongoDbOrderStatusEnum.PAYMENT_CONFIRMED },
              { 'fullDocument.status': MongoDbOrderStatusEnum.ORDER_SUBSTITUTE_EDIT },
              { 'fullDocument.status': MongoDbOrderStatusEnum.PAYMENT_FAILED },
              {
                'fullDocument.status': MongoDbOrderStatusEnum.ALREADY_PICKED_UP,
                'fullDocument.serverUpdatedOn': {
                  $gte: new Date(today),
                },
              },
            ],
          },
        ],
      },
    };
    const changeStream = ordersCollection.watch(filter);
    const watchData = async () => {
      try {
        for await (const change of changeStream) {
          emitter(change);
        }
      } catch (e) {
        throw e;
      }
    };
    watchData();
    return () => {
      changeStream.return('closed');
    };
  });
};

export const updateOrdersPrintingSummary = async (
  orderId: string,
  invoice: boolean,
  packingSlip: boolean,
  waiverForm: boolean,
  user: IMongodbUser,
) => {
  try {
    const ordersCollection = getCollection('orders');

    let printSet: any = {
      'printSummary.printedBy': {
        name: user.name,
        email: user.email,
      },
      'printSummary.printedOn': new Date(),
    };

    if (invoice) {
      printSet = { ...printSet, 'printSummary.invoice': invoice };
    }
    if (packingSlip) {
      printSet = { ...printSet, 'printSummary.packingSlip': packingSlip };
    }
    if (waiverForm) {
      printSet = { ...printSet, 'printSummary.waiverForm': waiverForm };
    }

    await ordersCollection.updateOne(
      { _id: orderId },
      {
        $set: printSet,
      },
    );
    const mongodbOrder = await ordersCollection.findOne({ _id: orderId });
    return { mongodbOrder };
  } catch (err) {
    console.log('error: ', err);
    throw err;
  }
};

export const updateOrdersStatusHistory = async (
  orderId: string,
  updatedFrom: IMongoDbOrderStatus,
  updatedTo: IMongoDbOrderStatus,
  bopisStatus: ShopifyBopisStatusEnum,
  locationCode: string,
  packerCode = '',
  packerInitial = '',
) => {
  try {
    const res = await callPageFunction(
      `/api/private/orders/updateBopisStatus?status=${bopisStatus}&orderId=${orderId}&updatedTo=${updatedTo}&updatedFrom=${updatedFrom}${locationCode ? `&locationCode=${locationCode}` : ''}`,
      {
        body: {
          packerCode,
          packerInitial,
        },
      },
    );
    return res;
  } catch (err) {
    console.log('error while updating: ', err);
    throw err;
  }
};

export const updateOrdersFulfillmentStatus = async (
  orderId: string,
  fulfillmentStatus: IMongodbOrderFulfillmentStatus,
  locationCode: string,
  order: IOrder,
  user: IMongodbUser,
): Promise<{ mongodbOrder: IMongodbOrder; shopifyOrder: IShopifyOrder }> => {
  try {
    const totalQuantity =
      order.ordersProduct.find(
        lineItem => lineItem.product.legacyResourceId === fulfillmentStatus.productId,
      )?.quantity || 0;
    const bodyToSend = {
      fulfillmentStatus,
      totalQuantity,
      orderId,
      locationCode,
    };
    const result = await callPageFunction(`/api/private/orders/updateFulfillment`, {
      body: bodyToSend,
      method: 'POST',
    });
    return { mongodbOrder: result.mongodbOrder, shopifyOrder: result.shopifyOrder };
  } catch (err) {
    console.log('error while updating: ', err);
    throw err;
  }
};

export const markOrderReadyForPickup = async (
  fulfillmentOrderId: string,
  orderId: string,
  totalQuantity: number,
  packerCode = '',
  packerInitial = '',
) => {
  try {
    const res = await callPageFunction(
      `/api/private/orders/markOrderReadyForPickup?fulfillmentOrderId=${fulfillmentOrderId}&orderId=${orderId}&totalQuantity=${totalQuantity}`,
      {
        body: {
          packerCode,
          packerInitial,
        },
      },
    );
    return res;
  } catch (err) {
    console.log('error in cloudflare function: ', err);
    throw err;
  }
};
export const getSubstitutesProduct = async (
  productType?: string,
  price?: number,
  nodes?: string,
): Promise<ISubstitutesProduct[] | never> => {
  try {
    if (nodes?.length) {
      const shopifyOrders = await callPageFunction(
        `/api/private/orders/getSubstituteProductsByNodes?nodes=${nodes}`,
      );
      return shopifyOrders;
    } else if (productType && price) {
      const shopifyOrders = await callPageFunction(
        `/api/private/orders/getSubstituteProducts?price=${price}&productType=${productType}`,
      );

      return shopifyOrders;
    }
    throw new Error('Either productType or price and nodes are required');
  } catch (err) {
    console.log('error in cloudflare function: ', err);
    throw err;
  }
};
export const markOrderFulfilled = async (
  orderId: string,
  locationCode: string,
  updatedFrom: MongoDbOrderStatusEnum,
  packerCode = '',
  packerInitial = '',
) => {
  try {
    // console.log('payload: ', orderId, locationCode, updatedFrom);
    const res = await callPageFunction(
      `/api/private/orders/markOrderFulfilled?orderId=${orderId}&updatedFrom=${updatedFrom}&locationCode=${locationCode}`,
      {
        body: {
          packerCode,
          packerInitial,
        },
      },
    );
    return res;
  } catch (err) {
    console.log('error in cloudflare function: ', err);
    throw err;
  }
};

export const getOrdersCount = async (locationCode: string): Promise<IStoreOrdersCount | never> => {
  const ordersCollection = getCollection('orders');

  try {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const data = await ordersCollection.aggregate([
      {
        $match: {
          $and: [
            { fulfillmentLocationCode: locationCode },
            {
              $or: [
                { status: MongoDbOrderStatusEnum.NEEDS_PACKING },
                { status: MongoDbOrderStatusEnum.PACKING_STARTED },
                {
                  status: MongoDbOrderStatusEnum.CANCELED,
                  statusHistory: {
                    $elemMatch: {
                      updatedTo: { $in: ['PACKING_STARTED', 'READY_FOR_PICKUP', 'UNFULFILLABLE'] },
                    },
                  },
                },
                { status: MongoDbOrderStatusEnum.UNFULFILLABLE },
                { status: MongoDbOrderStatusEnum.READY_FOR_PICKUP },
                { status: MongoDbOrderStatusEnum.PAYMENT_CONFIRMED },
                { status: MongoDbOrderStatusEnum.ORDER_SUBSTITUTE_EDIT },
                { status: MongoDbOrderStatusEnum.PAYMENT_FAILED },
                {
                  status: MongoDbOrderStatusEnum.ALREADY_PICKED_UP,
                  serverUpdatedOn: {
                    $gte: new Date(today),
                  },
                },
              ],
            },
          ],
        },
      },
      { $group: { _id: '$status', count: { $sum: 1 } } },
    ]);
    const result: IStoreOrdersCount = {};
    for (const item of data as { _id: IMongoDbOrderStatus; count: number }[]) {
      result[item._id] = item.count;
    }
    return result;
  } catch (err) {
    throw err;
  }
};

export const findOrders = async (
  queries: Partial<IFindOrdersQuery>,
  locationCode: string,
  startValue?: Date,
  sortKey?: string,
  sortBy?: 'ASC' | 'DSC',
): Promise<{ orders: IOrder[]; count: number }> => {
  try {
    const bodyToSend = {
      queries,
      locationCode,
      startValue: startValue || null,
      sortKey: sortKey || null,
      sortBy: sortBy || null,
    };

    const result = (await callPageFunction(`/api/private/orders/findOrders`, {
      method: 'POST',
      body: bodyToSend,
    })) as {
      shopifyOrders: IShopifyOrder[];
      mongodbOrders: IMongodbOrder[];
      count: number;
    };

    const orders = result.mongodbOrders?.map((mongoDbOrder, index) => {
      if (mongoDbOrder._id !== result.shopifyOrders?.[index].id) {
        const shopifyOrder = result.shopifyOrders.find(order => order.id === mongoDbOrder._id);
        if (shopifyOrder) {
          return getSimplifiedOrder(mongoDbOrder, shopifyOrder);
        }
      }
      return getSimplifiedOrder(mongoDbOrder, result.shopifyOrders?.[index]);
    });
    return { orders, count: result.count };
  } catch (err) {
    console.log('error in cloudflare function: ', err);
    throw err;
  }
};
